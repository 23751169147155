<template>
  <EventsLayout
    :week="week"
    :withRange="withRange"
    with-series-add
    with-instance-add
    @update:week="(v) => $emit('update:week', v)"
    @updateSeries="(v, id) => $emit('updateSeries', v, id)"
    @deleteSeries="(id) => $emit('deleteSeries', id)"
    @setInstance="(v) => $emit('setInstance', v)"
    @unsetInstance="(v) => $emit('unsetInstance', v)"
  >
    <template #default="{ attrs, on }">
      <StaffEventsView
        v-bind="attrs"
        v-on="on"
        :min-box-width="minBoxWidth"
        :min-box-height="minBoxHeight"
        :max-box-width="maxBoxWidth"
        :max-box-height="maxBoxHeight"
      >
        <template #footer>
          <Footer />
        </template>
      </StaffEventsView>
    </template>
  </EventsLayout>
</template>

<script>
import EventsLayout from "@/components/draft/EventsLayout";
import StaffEventsView from "@/components/draft/StaffEventsView";
import Footer from "@/components/home/Footer";
export default {
  name: "StaffDraft",
  components: {
    EventsLayout,
    StaffEventsView,
    Footer,
  },
  props: {
    week: { type: Number },
    withRange: { type: Boolean },
    minBoxWidth: { type: Number },
    maxBoxWidth: { type: Number },
    minBoxHeight: { type: Number },
    maxBoxHeight: { type: Number },
  },
};
</script>
